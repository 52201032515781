<template>
    <div class="serverDetail">
        <el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item >业务详情</el-breadcrumb-item>
		</el-breadcrumb>
        <div class="businessDetail">
            <div class="topType">
				<span class="text">业务详情</span>
			</div>
            <div class="businessDetailList">
                <el-table :data="serverData">
					<el-table-column prop="id" label="订单编号" align="center"></el-table-column>
      				<el-table-column prop="ppsOrder" label="pps数量" align="center"></el-table-column>
                    <el-table-column prop="ppsAmount" label="价格" align="center"></el-table-column>
                    <el-table-column prop="" label="托管时间(月)" align="center">
                        <template slot-scope="scope">
							<span>({{scope.row.trusteeshipFeeMonth}}/{{scope.row.trusteeshipMonth}})</span>
						</template>
                    </el-table-column>
                    <el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>
					<el-table-column label="订单状态" align="center">
						<template slot-scope="scope">
							<span style="color: #84CE61;" v-if="scope.row.status==10">审核通过</span>
					        <span style="color: #1989FA;" v-if="scope.row.status==1">待审核</span>
					        <span style="color: #FF8C8D;" v-if="scope.row.status==0">未支付</span>
					        <span style="color: #1989FA;" v-if="scope.row.status==-1">用户取消</span>
					        <span style="color: #FF8C8D;" v-if="scope.row.status==-10">审核不通过</span>
						</template>
					</el-table-column>
    			</el-table>
            </div>
        </div>
        <div class="trusteeshipDetaial">
            <div class="topType">
				<span class="text">托管订单列表</span>
			</div>
            <div class="trusteeshipList">
                <el-tabs type="border-card" @tab-click="changeTab">
  				<el-tab-pane label="全部" name="">
					  <div class="transactionList">
						<el-table :data="ListData" >
							<el-table-column prop="id" label="托管订单编号" align="center"></el-table-column>
      						<el-table-column prop="price" label="单价" align="center"></el-table-column>
							<el-table-column prop="month" label="数量" align="center"></el-table-column>
							<el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>
							<el-table-column prop="amount" label="总价" align="center" style="color: #FF8C8D;"></el-table-column>
							<el-table-column label="未通过原因" align="center">
								<template slot-scope="scope">
								    <span v-if="scope.row.status==-1">{{scope.row.auditReason}}</span>
								</template>
							</el-table-column>
							<el-table-column label="审核状态" align="center">
								<template slot-scope="scope">
								    <span style="color: #1989FA;" v-if="scope.row.status==1">审核通过</span>
									<span style="color: #FF8C8D;" v-if="scope.row.status==-1">审核未通过</span>
									<span style="color: #84CE61;" v-if="scope.row.status==0">待审核</span>
								</template>
							</el-table-column>
    					</el-table>
						<div class="block">
						    <el-pagination  
							@current-change="Page" 
							layout="prev, pager, next, jumper" 
							:page-size="page.pagesize"
							:current-page="page.currentPage"
							:total="page.total">
							</el-pagination>
						</div>
					  </div>
				</el-tab-pane>
  				<el-tab-pane label="通过" name="1">
					  <div class="transactionList">
						<el-table :data="ListData" style="width: 100%">
							<el-table-column prop="id" label="托管订单编号" align="center"></el-table-column>
							<el-table-column prop="price" label="单价" align="center"></el-table-column>
							<el-table-column prop="month" label="数量" align="center"></el-table-column>
							<el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>
							<el-table-column prop="amount" label="总价" align="center" style="color: #FF8C8D;"></el-table-column>
							<el-table-column label="未通过原因" align="center">
								<template slot-scope="scope">
								    <span v-if="scope.row.status==-1">{{scope.row.auditReason}}</span>
								</template>
							</el-table-column>
							<el-table-column label="审核状态" align="center">
								<template slot-scope="scope">
								    <span style="color: #1989FA;" v-if="scope.row.status==1">审核通过</span>
									<span style="color: #FF8C8D;" v-if="scope.row.status==-1">审核未通过</span>
									<span style="color: #84CE61;" v-if="scope.row.status==0">待审核</span>
								</template>
							</el-table-column>
    					</el-table>
						<div class="block">
						    <el-pagination  
							@current-change="Page" 
							layout="prev, pager, next, jumper" 
							:page-size="page.pagesize"
							:current-page="page.currentPage"
							:total="page.total">
							</el-pagination>
						</div>
					  </div>
				</el-tab-pane>
                <el-tab-pane label="未通过" name="-1">
					  <div class="transactionList">
						<el-table :data="ListData" style="width: 100%">
							<el-table-column prop="id" label="托管订单编号" align="center"></el-table-column>
							<el-table-column prop="price" label="单价" align="center"></el-table-column>
							<el-table-column prop="month" label="数量" align="center"></el-table-column>
							<el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>
							<el-table-column prop="amount" label="总价" align="center" style="color: #FF8C8D;"></el-table-column>
							<el-table-column label="未通过原因" align="center">
								<template slot-scope="scope">
								    <span v-if="scope.row.status==-1">{{scope.row.auditReason}}</span>
								</template>
							</el-table-column>
							<el-table-column label="审核状态" align="center">
								<template slot-scope="scope">
								    <span style="color: #1989FA;" v-if="scope.row.status==1">审核通过</span>
									<span style="color: #FF8C8D;" v-if="scope.row.status==-1">审核未通过</span>
									<span style="color: #84CE61;" v-if="scope.row.status==0">待审核</span>
								</template>
							</el-table-column>
    					</el-table>
						<div class="block">
						    <el-pagination  
							@current-change="Page" 
							layout="prev, pager, next, jumper" 
							:page-size="page.pagesize"
							:current-page="page.currentPage"
							:total="page.total">
							</el-pagination>
						</div>
					  </div>
				</el-tab-pane>
			</el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import {
		Server
	} from "@/api/index";
export default {
    data() {
        return {
			ListData:[],
            id: '',
            page:{
			total:0,
			pagesize:10,
			currentPage: 1,
		    },
            // pagesuccess:{
            //     total:0,
            //     pagesize:10,
            //     currentPage: 1,
            // },
            // pagefail:{
            //     total:0,
            //     pagesize:10,
            //     currentPage: 1,
            // },
            serverData:[],
			page:{
				total:0,
				pagesize:10,
				currentPage: 1,
			},
			currentstatus: ''
        }
    },
    created() {
        let {id} = this.$route.query;
		if (this.id == '') {
			this.id = id
		}
        this.serverInfo();
		this.List();
    },
    methods:{
        //详情
		async serverInfo() {
			let {
				status,
				data
			} = await Server.kuangInfo({
				id: this.id
			});
			if (status) {
				if (data.code == 0) {
					this.serverData.push(data.data.Detail);
				}
			}
		},
        //去支付
		goPay(id) {
			this.$router.push({
				path: "/server/second",
				query: {
					id: id
				}
			})
		},
        changeTab(val) {
			this.currentstatus = val.name
			this.ListData = []
			this.page.currentPage = 1
			this.List()
		},
		async Page(val) {
			this.page.currentPage = val;
			this.List(this.page.currentPage)
		},
		//托管订单列表
		async List(page) {
				let {
					status,
					data
				} = await Server.trusteeshiplist({
					orderId:this.id,
					PageNum: page,
					status: this.currentstatus,
				});
				if (status) {
					if (data.code == 0) {
						if (data.data.list == null) {
						} else {
							this.ListData = data.data.list;
							this.page.total = data.data.total;
							this.page.currentPage = data.data.currentPage;
						}
					}
				}
		},
    },

}
</script>

<style lang="less">
    .serverDetail {
        body {
			margin: 0;
			padding: 0;
			background-color: #F0F3FA;
		}
        .el-breadcrumb{
			padding: 30px;
			.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}
        .businessDetail {
            background-color: #fff;
			margin: 20px;
            border-radius: 10px;
            .topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}
			.topType::before{
				content:"";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}
            .businessDetailList {
                padding: 10px;
                .listTop {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                }
            }
        }
        .trusteeshipDetaial {
            background-color: #fff;
			margin: 20px;
            border-radius: 10px;
            .topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}
			.topType::before{
				content:"";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}
            .trusteeshipList {
                padding: 10px;
            }
			.el-pagination {
				text-align: right;
				padding: 10px 10px 0 0;
			}
        }
    }
</style>